export const themeConstants = {
  colors: {
    black: '#000000',
    blue: {
      100: '#E6F5FD',
      200: '#E0F1FF',
      300: '#A6D7FF',
      400: '#E6F5FD',
      500: '#006699', // Primary blue from header *** check header should be blue-600
      600: '#00476B',
      700: '#003366',
    },
    gold: {
      100: '#FFF9E5',
      500: '#FFB550',
    },
    green: {
      100: '#DEFADE',
      500: '#006600',
    },
    grey: {
      100: '#F9F9F9', // Banner background *** check this should be #F0F0F0
      200: '#EBEBEB', // Footer background
      300: '#CCCCCC',
      400: '#666666',
      500: '#444444',
    },
    red: {
      100: '#FFEDED',
      500: '#CC3333',
    },
    white: '#FFFFFF',

    error: {
      main: 'Red-500', // Get from Figma
    },
    success: {
      main: 'Green-500',
      light: 'Green-100',
    },
    warning: {
      main: 'Gold-500',
      light: 'Gold-100',
    },
    common: {
      white: '#FFFFFF',
      black: '#000000',
    },
  },

  typography: {
    h1: {
      fontSize: '2.5rem', // 40px
      lineHeight: '3.75rem', // 60px
      fontWeight: 700,
    },
    h2: {
      fontSize: '1.75rem', // 28px
      lineHeight: '2.625rem', // 42px
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.5rem', // 24px
      lineHeight: '2.25rem', // 36px
      fontWeight: 600,
    },
    h4: {
      fontSize: '1.25rem', // 20px
      lineHeight: '1.875rem', // 30px
      fontWeight: 600,
    },
    h5: {
      fontSize: '1rem', // 16px
      lineHeight: '1.5rem', // 24px
      fontWeight: 600,
    },
    body: {
      fontSize: '1rem', // 16px
      lineHeight: '1.5rem', // 24px
      fontWeight: 400,
    },
    placeholder: {
      fontSize: '1rem', // 16px
      lineHeight: '1.5rem', // 24px
      fontWeight: 400,
    },
    caption: {
      fontSize: '0.875rem', // 14px
      lineHeight: '1.313rem', // 21px
      fontWeight: 400,
    },
    verticalNav: {
      fontSize: '1rem', // 16px
      lineHeight: '1.5rem', // 24px
      fontWeight: 500,
    },
    secondaryBody: {
      fontSize: '1rem', // 16px
      lineHeight: '1.313rem', // 21px
      fontWeight: 400,
    },
  },

  effects: {
    shadows: {
      primary: '[GET_FROM_FIGMA]',
      onAction: '[GET_FROM_FIGMA]',
    },
  },
};
