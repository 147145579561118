import React from 'react';
import { Box, Container, Typography, Link, Grid, IconButton, SxProps, Theme } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import uscisInsignia from 'assets/USCIS_Insignia_wm_fc.png';
import { themeConstants } from '../../theme/constants';
// interface FooterProps {}

interface FooterLink {
  text: string;
  url: string;
}

const styles = {
  link: {
    color: '#00476B',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  socialIcon: {
    color: '#00476B',
  },
  footerLink: {
    display: 'block',
    color: 'inherit',
    textDecoration: 'underline',
    fontSize: themeConstants.typography.body,
    lineHeight: '1.4',
    mb: 1,
    '&:hover': {
      textDecoration: 'none',
    },
  },
} satisfies Record<string, SxProps<Theme>>;

const footerColumns: FooterLink[][] = [
  [
    { text: 'About USCIS', url: 'https://www.uscis.gov/about-us' },
    { text: 'Accessibility', url: 'https://www.uscis.gov/website-policies/accessibility' },
    { text: 'Budget and Performance', url: 'https://www.uscis.gov/about-us/budget-planning-and-performance' },
    { text: 'DHS Components', url: 'https://www.uscis.gov/website-policies/dhs-component-websites' },
  ],
  [
    {
      text: 'Freedom of Information Act',
      url: 'https://www.uscis.gov/records/request-records-through-the-freedom-of-information-act-or-privacy-act',
    },
    {
      text: 'No FEAR Act Data',
      url: 'https://www.uscis.gov/about-us/equal-employment-opportunity/equal-employment-opportunity-data-posted-pursuant-to-the-no-fear-act',
    },
    {
      text: 'Privacy and Legal Disclaimers',
      url: 'https://www.uscis.gov/website-policies/privacy-and-legal-disclaimers',
    },
    { text: 'Site Map', url: 'https://www.uscis.gov/sitemap' },
  ],
  [
    { text: 'Office of the Inspector General', url: 'https://www.oig.dhs.gov/' },
    { text: 'The White House', url: 'https://www.whitehouse.gov/' },
    { text: 'USA.gov', url: 'https://www.usa.gov/' },
  ],
];

const Footer: React.FC = () => {
  const scrollToTop = (): void => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const currentYear = new Date().getFullYear();

  const socialLinks = [
    { icon: FacebookIcon, label: 'Facebook', url: 'https://www.facebook.com/uscis' },
    { icon: XIcon, label: 'Twitter', url: 'https://x.com/uscis' },
    { icon: YouTubeIcon, label: 'YouTube', url: 'https://www.youtube.com/uscis' },
    { icon: InstagramIcon, label: 'Instagram', url: 'https://www.instagram.com/uscis/' },
    { icon: LinkedInIcon, label: 'LinkedIn', url: 'https://www.linkedin.com/company/uscis' },
    {
      icon: MailOutlineIcon,
      label: 'Subscriber',
      url: 'https://public.govdelivery.com/accounts/USDHSCIS/subscriber/new',
    },
  ];

  return (
    <Box component="footer">
      {/* Row 1: Return to top */}
      <Box sx={{ bgcolor: 'common.white', py: 2 }}>
        <Container maxWidth="lg">
          <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Link
              component="button"
              onClick={scrollToTop}
              sx={{
                ...styles.link,
                textDecoration: 'underline',
              }}
            >
              Return to top
            </Link>
          </Box>
        </Container>
      </Box>

      {/* Row 2: Logo and Social Media */}
      <Box sx={{ bgcolor: '#EBEBEB', py: 3 }}>
        <Container maxWidth="lg">
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid
              item
              xs={12}
              md={6}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={uscisInsignia}
                  width={208}
                  height={61}
                  alt="USCIS Insignia"
                  style={{ marginRight: '16px' }}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: { xs: 'center', md: 'flex-end' },
                  mt: { xs: 2, md: 0 },
                }}
              >
                <Box sx={{ mb: 2 }}>
                  {socialLinks.map(({ icon: Icon, label, url }) => (
                    <IconButton
                      key={label}
                      aria-label={label}
                      sx={styles.socialIcon}
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon />
                    </IconButton>
                  ))}
                </Box>
                <Link
                  href="https://www.uscis.gov/about-us/contact-us"
                  sx={{
                    ...styles.link,
                    fontSize: '0.875rem',
                  }}
                >
                  Contact Us
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Row 3: Footer links */}
      <Box sx={{ bgcolor: '#00476B', color: 'common.white', py: 4 }}>
        <Container maxWidth="lg">
          <Grid
            container
            spacing={3}
            sx={{
              justifyContent: { md: 'flex-start' },
              maxWidth: { md: '60%' },
            }}
          >
            {footerColumns.map((column, columnIndex) => (
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                key={columnIndex}
              >
                {column.map((link) => (
                  <Link
                    key={link.text}
                    href={link.url}
                    sx={styles.footerLink}
                    underline="always"
                  >
                    {link.text}
                  </Link>
                ))}
              </Grid>
            ))}
          </Grid>
          <Box
            mt={4}
            pt={3}
            sx={{ borderTop: '1px solid rgba(255, 255, 255, 0.1)' }}
          >
            <Typography
              variant="body2"
              align="center"
              sx={{ fontSize: '0.75rem' }}
            >
              © {currentYear} U.S. Citizenship and Immigration Services
            </Typography>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
