import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import heroBanner from 'assets/cloudsFLagCapitol.png';
import { Link as RouterLink } from 'react-router-dom';
import { HeroBannerProps } from 'src/types/containers.types';

// Styled components
const GradientOverlay = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'linear-gradient(257.75deg, rgba(0, 0, 0, 0) 30.07%, rgba(0, 0, 0, 0.6) 57.71%)',
  zIndex: 1,
});

const ContentContainer = styled(Container)({
  position: 'relative',
  zIndex: 2,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  color: '#fff',
});

const HeroBanner: React.FC<HeroBannerProps> = ({ backgroundImage = heroBanner }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        height: '560px',
        padding: '96px 0',
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          zIndex: 0,
        },
      }}
    >
      <GradientOverlay />
      <ContentContainer maxWidth="lg">
        <Box sx={{ maxWidth: '600px' }}>
          <Typography
            variant="h1"
            component="h1"
            sx={{
              fontSize: { xs: '2.5rem', md: '3.5rem' },
              fontWeight: 600,
              marginBottom: 2,
              lineHeight: 1.2,
            }}
          >
            Apply for immigration benefits today.
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: '1rem', md: '1.125rem' },
              marginBottom: 4,
              opacity: 0.9,
            }}
          >
            Testing. Lorem ipsum. Support the callout with short explanatory text. You don&apos;t need more than a
            couple sentences.
          </Typography>
          <Button
            variant="contained"
            size="large"
            sx={{
              backgroundColor: '#fff',
              color: '#000',
              padding: '12px 32px',
              fontSize: '1rem',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#f7f7f7',
              },
            }}
            component={RouterLink}
            to="/form"
          >
            Apply now
          </Button>
        </Box>
      </ContentContainer>
    </Box>
  );
};

export default HeroBanner;
