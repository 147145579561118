export const KEYCLOAK_CONFIG = {
  url: 'https://keycloak.rapid2-cc-security-fork.rapid.intellibridgelabs.io/',
  realm: 'master',
  clientId: 'rapid2-cc-open-id',
};

export const OIDC_CONFIG = {
  authority: `${KEYCLOAK_CONFIG.url}realms/${KEYCLOAK_CONFIG.realm}`,
  client_id: KEYCLOAK_CONFIG.clientId,
  redirect_uri: window.location.origin,
  post_logout_redirect_uri: window.location.origin,
  onSigninCallback: (): void => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
} as const;

export default OIDC_CONFIG;
