import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { LandingPage, LoginPage, ThemeShowcase } from '../pages';
import PrivateRoute from './PrivateRoute';
import { useAuth } from 'react-oidc-context';
import { Box, Button } from '@mui/material';
import RootLayout from '../RootLayout';

// TODO: Form should not exist here, it shouldn't have in the prior repo been in App.tsx, we will figure a container/page for this when we portover.
export const Form = (): JSX.Element => {
  const auth = useAuth();

  const handleLogout = async (): Promise<void> => {
    const currUrlArr = window.location.href.split('/');
    const domain = `${currUrlArr[0]}//${currUrlArr[2]}`;
    await auth.signoutRedirect({
      post_logout_redirect_uri: domain,
    });
  };

  return (
    <div>
      <Box sx={{ mt: 'auto' }}>
        {/* prettier-ignore */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleLogout}
          className="button-temp"
        >
          Log out
        </Button>
      </Box>
      <h1>I131Form</h1>
    </div>
  );
};

const isDevelopment = process.env.NODE_ENV === 'development';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      {
        index: true,
        element: <LandingPage />,
      },
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'form',
        element: (
          <PrivateRoute>
            <Form />
          </PrivateRoute>
        ),
      },
      ...(isDevelopment
        ? [
            {
              path: 'showcase',
              element: <ThemeShowcase />,
            },
          ]
        : []),
    ],
  },
]);

export default router;
