import React from 'react';
import { Box, Container, Typography, Paper, Grid, Divider, useTheme, Card, CardContent, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { themeConstants } from '../../theme/constants';
import { styleConstants } from '../../styles/constants';

const ColorBox = styled(Box)(({ theme }) => ({
  width: '100px',
  height: '100px',
  margin: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.common.white,
  position: 'relative',
  '&::after': {
    content: 'attr(data-color)',
    position: 'absolute',
    bottom: '8px',
    fontSize: '12px',
  },
}));

const Section = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const ThemeShowcase: React.FC = () => {
  const theme = useTheme();

  return (
    <Container
      maxWidth="lg"
      sx={{ py: 4 }}
    >
      <Typography
        variant="h1"
        gutterBottom
      >
        Theme Showcase
      </Typography>

      <Section>
        <Typography
          variant="h2"
          gutterBottom
        >
          Typography
        </Typography>
        <Grid
          container
          spacing={2}
        >
          {Object.entries(themeConstants.typography).map(([key, value]) => (
            <Grid
              item
              xs={12}
              key={key}
            >
              <Box sx={{ mb: 2 }}>
                <Typography variant="overline">
                  {key}: {value.fontSize}/{value.lineHeight} - Weight: {value.fontWeight}
                </Typography>
                <Typography sx={value}>Happiness is the only thing that multiplies when you share it.</Typography>
              </Box>
              <Divider />
            </Grid>
          ))}
        </Grid>
      </Section>

      <Section>
        <Typography
          variant="h2"
          gutterBottom
        >
          Colors
        </Typography>
        <Typography
          variant="h3"
          gutterBottom
        >
          Blue Palette
        </Typography>
        <Box
          display="flex"
          flexWrap="wrap"
        >
          {Object.entries(themeConstants.colors.blue).map(([key, value]) => (
            <ColorBox
              key={key}
              sx={{ bgcolor: value }}
              data-color={`Blue-${key}`}
            >
              {value}
            </ColorBox>
          ))}
        </Box>

        <Typography
          variant="h3"
          gutterBottom
          sx={{ mt: 3 }}
        >
          Grey Palette
        </Typography>
        <Box
          display="flex"
          flexWrap="wrap"
        >
          {Object.entries(themeConstants.colors.grey).map(([key, value]) => (
            <ColorBox
              key={key}
              sx={{ bgcolor: value, color: 'text.primary' }}
              data-color={`Grey-${key}`}
            >
              {value}
            </ColorBox>
          ))}
        </Box>

        <Typography
          variant="h3"
          gutterBottom
          sx={{ mt: 3 }}
        >
          Semantic Colors
        </Typography>
        <Box
          display="flex"
          flexWrap="wrap"
        >
          <ColorBox
            sx={{ bgcolor: 'error.main' }}
            data-color="Error"
          >
            Error
          </ColorBox>
          <ColorBox
            sx={{ bgcolor: 'success.main' }}
            data-color="Success"
          >
            Success
          </ColorBox>
          <ColorBox
            sx={{ bgcolor: 'warning.main' }}
            data-color="Warning"
          >
            Warning
          </ColorBox>
        </Box>
      </Section>

      <Section>
        <Typography
          variant="h2"
          gutterBottom
        >
          Spacing
        </Typography>
        <Box sx={{ bgcolor: 'grey.100', p: 2 }}>
          {[1, 2, 3, 4, 6, 8].map((space) => (
            <Box
              key={space}
              sx={{
                width: theme.spacing(space),
                height: '20px',
                bgcolor: 'primary.main',
                mb: 1,
              }}
            >
              <Typography variant="caption">{theme.spacing(space)}</Typography>
            </Box>
          ))}
        </Box>
      </Section>

      <Section>
        <Typography
          variant="h2"
          gutterBottom
        >
          Component Examples
        </Typography>

        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            md={6}
          >
            <Card>
              <CardContent>
                <Typography
                  variant="h4"
                  gutterBottom
                >
                  Form Fields
                </Typography>
                <Box sx={{ ...styleConstants.components.formField }}>
                  <Typography
                    variant="body2"
                    gutterBottom
                  >
                    Margin Bottom: {styleConstants.components.formField.marginBottom}
                  </Typography>
                  <Button variant="contained">Example Button</Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
          >
            <Card>
              <CardContent>
                <Typography
                  variant="h4"
                  gutterBottom
                >
                  Layout Constants
                </Typography>
                <Box>
                  <Typography variant="body2">Header Height: {styleConstants.layout.header.height}</Typography>
                  <Typography variant="body2">Mobile Header: {styleConstants.layout.header.mobileHeight}</Typography>
                  <Typography variant="body2">Form Max Width: {styleConstants.layout.form.maxWidth}</Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Section>

      <Section>
        <Typography
          variant="h2"
          gutterBottom
        >
          Effects
        </Typography>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            md={6}
          >
            <Card elevation={1}>
              <CardContent>
                <Typography variant="h5">Shadow Primary</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >
            <Card elevation={2}>
              <CardContent>
                <Typography variant="h5">Shadow onAction</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Section>

      <Section>
        <Typography
          variant="h2"
          gutterBottom
        >
          Breakpoints
        </Typography>
        <Grid
          container
          spacing={2}
        >
          {Object.entries(styleConstants.breakpoints.values).map(([key, value]) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={key}
            >
              <Card>
                <CardContent>
                  <Typography variant="h6">
                    {key.toUpperCase()}: {value}px
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Section>
    </Container>
  );
};

export default ThemeShowcase;
