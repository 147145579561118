import React from 'react';
import { Grid, Box, useTheme, useMediaQuery, Container, Button, Card, CardContent, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import HeroBanner from '../HeroBanner';
import { themeConstants } from '../../theme/constants';
import { styleConstants } from '../../styles/constants';
import { cardData } from './constants';

const LandingCards: React.FC = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <Box bgcolor={themeConstants.colors.grey[100]}>
      {!isXs && <HeroBanner />}
      <Container
        maxWidth="lg"
        sx={{ mt: { sm: 4 } }}
      >
        <Grid
          container
          spacing={3}
          justifyContent="center"
        >
          {cardData.map((card, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={index}
            >
              <Card
                sx={{
                  height: '364px',
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: '325px',
                }}
              >
                <CardContent
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 3,
                  }}
                >
                  <Typography
                    marginBottom={styleConstants.layout.gutter.bottom}
                    fontSize={themeConstants.typography.h3}
                  >
                    {card.title}
                  </Typography>

                  <Typography
                    color={themeConstants.colors.grey[500]}
                    fontSize={themeConstants.typography.body}
                    paragraph
                  >
                    {card.description}
                  </Typography>
                  <Box
                    sx={{ mt: 'auto' }}
                    width="100%"
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        color: themeConstants.colors.blue[500],
                        textTransform: 'none',
                      }}
                      fullWidth
                      disabled
                      component={RouterLink}
                      to={card.buttonLink}
                    >
                      {card.button}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default LandingCards;
