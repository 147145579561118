import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useAuth } from 'react-oidc-context';
import LockOpenIcon from '@mui/icons-material/LockOpen';

const LoginPage: React.FC = (): JSX.Element => {
  const auth = useAuth();

  const handleLogin = async (): Promise<void> => {
    await auth.signinRedirect();
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      textAlign="center"
    >
      <Typography
        variant="h2"
        sx={{
          fontSize: '2.5rem',
          mb: 4,
          fontWeight: 100,
          color: 'primary.main',
          textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
          letterSpacing: '0.05em',
          textTransform: 'uppercase',
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: '-10px',
            left: '10%',
            width: '80%',
            height: '1px',
            /* prettier-ignore */
            background: 'linear-gradient(90deg, transparent, #1976d2, transparent)',
          },
        }}
      >
        Welcome Back
      </Typography>
      <Button
        variant="contained"
        size="large"
        onClick={handleLogin}
        startIcon={<LockOpenIcon />}
        sx={{
          mt: 2,
          px: 4,
          py: 1.5,
          fontSize: '1.1rem',
          fontWeight: 500,
          textTransform: 'none',
          borderRadius: '50px',
          /* prettier-ignore */
          boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)',
          '&:hover': {
            /* prettier-ignore */
            boxShadow: '0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)',
          },
        }}
      >
        Login with Keycloak
      </Button>
    </Box>
  );
};

export default LoginPage;
